import axios, { AxiosRequestConfig } from 'axios';

interface RequestOptions extends AxiosRequestConfig {}

export const request = async (url: string, options?: RequestOptions) => {
  return axios(url, {
    ...options,
    method: 'POST',
    withCredentials: true,
  }).catch((error) => {
    throw error.response.data;
  });
};
